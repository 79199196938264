import {
  ButtonContainer,
  ContentContainer,
  DescriptionModal,
  FormGroup,
  FormLabel,
  FormSubLabel,
  ChoiceContainer,
} from "../modalManageSpacePermission/ModalManageSpacePermission.style";

import { Button, Modal, TextInput } from "../../..";
import { Choice } from "../../../atoms/choice/Choice";
import { KeyboardEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setIsSpaceCreationShowing,
  createSpaces,
  resetErrorState,
} from "../../../../store/reducers/spaces";
import {
  validateSpaceName,
  validateStartAndEndWithCharOrNumber,
} from "../../../../utils/validate";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import { v4 as uuid } from "uuid";
import {
  resetLoadingState,
  setLoadingState,
} from "../../../../store/reducers/loading";

export const ModalCreateSpace = () => {
  const { isSpaceCreationShowing } = useAppSelector((state) => state.spaces);
  const { loadingStatus } = useAppSelector((state) => state.loading);
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string>("");
  const [errorLabel, setErrorLabel] = useState<string>("");
  const [selected, setSelected] = useState<"owner-access" | "team-access">(
    "owner-access"
  );

  const onCloseModal = () => {
    dispatch(setIsSpaceCreationShowing(false));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && validateSpaceName(value)) {
      handleCreate();
    } else if (event.key === "Escape") {
      onCloseModal();
    }
  };

  const handleCreate = () => {
    dispatch(setLoadingState());
    const postData = async () => {
      const payload = {
        spaceKey: value,
        defaultPermission: selected,
      };

      dispatch(resetErrorState());
      const response = await dispatch(createSpaces(payload));

      if (createSpaces.fulfilled.match(response)) {
        const id = uuid();
        let toastData: ToastProps;

        if (!response.payload.errorCode) {
          dispatch(setIsSpaceCreationShowing(false));
          const space = value;
          toastData = {
            id,
            status: "success",
            title: "Space Created",
            description: `Space "${space}" has been created.`,
          };
          setValue("");
          setErrorLabel("");
          setSelected("owner-access");
        } else {
          let errorMessage: string;
          switch (response.payload.errorCode) {
            case "spaceAlreadyExists":
              // Space with the same name already exists.
              // So skip it.
              errorMessage = "";
              setErrorLabel("Space with the same name already exists.");
              break;
            case "internalServerError":
              errorMessage =
                "There was an error while creating Space. Please try again.";
              setErrorLabel("");
              break;
            case "DatabaseTimeout":
              errorMessage =
                "There was an error while creating Space. Please try again.";
              setErrorLabel("");
              break;
            case "DatabaseNetworkError ":
              errorMessage =
                "There was an error while creating Space. Please try again.";
              setErrorLabel("");
              break;
            default:
              errorMessage = `Space couldn’t be created due to ${response.payload.errorCode}. Please try again.`;
              setErrorLabel("");
          }
          toastData = {
            id,
            status: "error",
            title: "Create Space Failed",
            description: errorMessage,
          };
        }

        if (toastData.description !== "") {
          dispatch(addToast(toastData));
          const timer = setTimeout(() => {
            dispatch(removeToast(id));
            clearTimeout(timer);
          }, 5000);
        }
        dispatch(resetLoadingState());
      }
    };
    postData();
  };

  useEffect(() => {
    setValue("");
    setSelected("owner-access");

    if (isSpaceCreationShowing) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isSpaceCreationShowing]);

  if (!isSpaceCreationShowing) {
    return <></>;
  }

  return (
    <Modal
      title={"New Space"}
      isOpen={isSpaceCreationShowing}
      onClose={onCloseModal}
    >
      <ContentContainer>
        <DescriptionModal>
          <strong> “Space” </strong> is ideal for
          grouping your buckets and helps you manage permission easier since the
          permission you set in the Space level will apply to all buckets and
          files inside. Fill your new Space name below to get started.
        </DescriptionModal>
        <FormGroup>
          <FormLabel style={{ marginBottom: 4 }}>Space Name</FormLabel>
          <FormSubLabel>
            Please name your Space wisely, you cannot change this later.
          </FormSubLabel>
          <TextInput
            text={value}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setValue(e.target.value);
              setErrorLabel("");
            }}
            placeHolder="spacename"
            label={
              errorLabel !== ""
                ? errorLabel
                : value.length <= 3
                ? "Space name must be between 3 and 63 characters long and can consist only of lowercase letters, numbers, underscore (_), and hyphens (-)."
                : !validateStartAndEndWithCharOrNumber(value)
                ? "Space name must start and end with lowercase letters or numbers."
                : "Space name must be between 3 and 63 characters long and can consist only of lowercase letters, numbers, underscore (_), and hyphens (-)."
            }
            error={
              (!validateSpaceName(value) ||
                !validateStartAndEndWithCharOrNumber(value) ||
                errorLabel !== "") &&
              Boolean(value.length)
            }
            testId="new-space-input"
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Permission</FormLabel>
          <ChoiceContainer>
            <Choice
              title={
                <>
                  <FontAwesomeIcon icon={["fas", "lock-alt"]} />
                  Personal Access
                </>
              }
              description={
                "Only you can manage buckets and files in this Space."
              }
              isSelected={selected === "owner-access"}
              onClick={() => {
                setSelected("owner-access");
              }}
              testId="choice-personal-access"
            />
          </ChoiceContainer>
          <ChoiceContainer>
            <Choice
              title={
                <>
                  <FontAwesomeIcon icon={["fas", "users"]} />
                  Team Access
                </>
              }
              description={
                "Everyone in your team can manage buckets and files in this Space."
              }
              isSelected={selected === "team-access"}
              onClick={() => {
                setSelected("team-access");
              }}
              testId="choice-team-access"
            />
          </ChoiceContainer>
        </FormGroup>
        <ButtonContainer>
          <Button
            type="submit"
            disable={
              !validateSpaceName(value) ||
              !validateStartAndEndWithCharOrNumber(value) ||
              loadingStatus === "loading"
            }
            onClick={handleCreate}
            fullWidth
            data-cy="new-space-submit"
          >
            Create
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  );
};
